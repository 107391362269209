$visuals-height: 700px;

.potato-treatments {
  &__end-spacing {
    height: 64px;
  }

  &__export-card {
    backdrop-filter: blur(8px);
    background-color: #ffffff98;
  }

  &__export-floating-container {
    bottom: calc(24px);
    position: fixed;
    right: calc(66px);
    z-index: 3;
  }

  &__switchers-container {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-end;
  }

  &__fields-map {
    flex: 1;
    height: $visuals-height;
    page-break-after: always;
    page-break-before: always;
  }

  &__chart-container {
    background: #fff;
    flex: 1;
    height: $visuals-height;
    position: relative;
  }

  &__weather-spin-container {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &__chart {
    height: calc($visuals-height / 2);
  }

  &__visuals-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
  }

  // scss-lint:disable ImportantRule, SelectorDepth
  // reason: antd table styles are hard to override
  td:has(.potato-treatments__recommendation-cell-gray) {
    background-color: #d9d9d9 !important;
  }

  td:has(.potato-treatments__recommendation-cell-green) {
    background-color: #b7eb8f !important;
  }

  td:has(.potato-treatments__recommendation-cell-red) {
    background-color: #ffa39e !important;
  }

  &__weather-block-selected {
    td:not(.plant-threat-scores__cell-custom-style) {
      background-color: #cae5ff !important;
    }
  }
  // scss-lint:enable ImportantRule, SelectorDepth

  &__centered-cell {
    display: flex;
    height: 100%;
    justify-content: center;
  }

  &__space {
    height: 32px;
  }

  &__header {
    align-items: center;
    display: flex;
    flex-direction: row;

    h1 {
      flex: 1;
    }

    .ant-select {
      min-width: 32px;
      width: unset;
    }
  }

  &__cell-with-small-text {
    display: flex;
    flex-direction: column;

    &__small-text {
      font-size: 12px;
      opacity: 0.5;
    }
  }
}
